<template>
  <div
    :style="`position: relative; cursor: pointer; height: ${height}px; width: ${width}px;`"
    class="thumbnail-img elevation-3 d-flex justify-center align-center"
    @click="viewImage"
  >
    <div v-if="decryptionError"
         :style="`height: ${height}px; width: ${width}px;`"
         class="text-center align-center mx-auto d-flex"
    >
      <v-icon color="error">
        warning
      </v-icon>
      <template v-if="decryptionError.status === 'SIGNER_NOT_FOUND'">
        The encryption key used to sign this image was not found.
      </template>
      <div v-else-if="decryptionError.message === 'Recipient defined with id is not found within message info during data decryption.'">
        Please restart this monitored device.  The image was encrypted using an old encryption key.
      </div>
      <template v-else-if="decryptionError.message === 'Could not get public key from the second argument.Expected a Virgil Card or a Public Key object. Got boolean'">
        Please close and reopen the Truple app. If this issue persists, please email support@truple.io and tell us code "dupekey".
      </template>
      <div v-else-if="decryptionError.message === 'This identity is not registered'">
        Please refresh the page or close and reopen the app to provide your E2EE Recovery Passcode.
      </div>
      <template v-else >
        <span class="text-truncate">{{decryptionError.message}}</span>
      </template>
    </div>
    <div
      v-else
      :class="['image-holder', image.risk, $store.state.viewSettings && $store.state.viewSettings.blurHighRiskScreenshots ? 'blur-high-risk' : '']"
      :style="`height: ${height}px; width: ${width}px; background-image: url('${imageSource}'); background-size: cover; background-repeat: no-repeat;`"
    >
    </div>

    <div v-if="showMetaData" :class="`meta-data ${image.risk}`" style="position: absolute; bottom: 0; left: 0; z-index: 2;">
      <div>{{image.deviceName}}</div>
<!--      <div>{{image.imageRatio}}</div>-->
      <div>
        <span style="float: left;">{{timestampString}}</span>
        <span style="float: right;">{{image.encrypted ? keyEmoji : ''}}</span>
        <v-icon style="float: right;" color="white" small v-if="image.neverBeforeSeen">fiber_new</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from "moment"
  import ImageCache from "../../services/ImageCache"
  import Constants from "../../Constants"
  import ImageUtils from "../../services/ImageUtils"

  const blankImage = '/img/blank.svg'

  let states = Object.freeze({
    encrypted: 'encrypted',
    decrypting: 'decrypting',
    decrypted: 'decrypted',
    error: 'error'
  })

  export default {
    name: "EncryptedThumbnail",
    props: {
      showMetaData: {
        type: Boolean,
        default: true
      },
      image: {
        type: Object
      },
      width: {
        type: Number
      },
      accountId: {
        type: String,
        // required: true
      },
      deviceId: {
        type: String,
        // required: true
      }
    },
    data() {
      return {
        keyEmoji: Constants.keyEmoji,
        newEmoji: Constants.newEmoji,
        imageSource: null,
        states: states,
        state: states.encrypted,
        decryptionError: null
      }
    },
    beforeMount() {
      this.handleNewImage()
    },
    beforeDestroy() {
    },
    methods: {
      viewImage() {
        this.$emit('on-click', this.image.fileKey)
      },
      async downloadAndDecryptThumbnail() {
        if(!this.deviceId || !this.accountId) {
          return
        }
        if(this.state !== states.encrypted) {
          return
        }
        if(!this.image) {
          return
        }

        this.state = states.decrypting
        try {
          let base64 = await ImageCache.getImage(this.accountId, this.deviceId, this.image.takenAt, this.image.thumbnailUrl)
          this.imageSource = base64
          this.state = states.decrypted
        } catch(e) {
          this.state = states.error
          this.decryptionError = e
          console.log('error decrypting')
          console.log(e)
        }
      },
      async downloadThumbnail() {
        if(!this.deviceId || !this.accountId) {
          return
        }
        if(!this.image) {
          return
        }

        let imageData = await fetch(this.image.thumbnailUrl)
        if(imageData.statusCode === 403) {
          throw new Error('Your credentials have expired, please reload the page.')
        }
        let dataBlob = await imageData.blob()

        let base64 = await ImageUtils.blobToBase64(dataBlob)
        this.imageSource = base64
        this.state = states.decrypted
      },
      handleNewImage() {
        if(this.image) {
          if(this.image.encrypted) {
            this.imageSource = blankImage
            this.stats = states.encrypted
            this.downloadAndDecryptThumbnail()
          } else {
            // this.imageSource = this.image.thumbnailUrl
            this.downloadThumbnail()
          }
        } else {
          this.imageSource = blankImage
          this.state = states.decrypted
        }
      }
    },
    // asyncComputed: {
    //   async appName() {
    //     if(!this.image) {
    //       return ''
    //     }
    //     if(!this.image.appName) {
    //       return ''
    //     }
    //     if(!this.image.encrypted) {
    //       return this.image.appName
    //     }
    //
    //     try {
    //       let e2eeHelper = E2EEHelperCache.getE2EEHelper(this.accountId)
    //
    //       let ret = await e2eeHelper.decryptString(this.deviceId, this.image.appName, this.image.takenAt)
    //       if(ret.length > 100) {
    //         ret = ret.substring(0, 100)
    //         ret += '...'
    //       }
    //       return ret
    //     } catch(e) {
    //       if(e.name) {
    //         let ret = `Error Decrypting: ${e.name}`
    //         if(e.message) {
    //           ret += `: ${e.message}`
    //         }
    //         return ret
    //       }
    //       return e
    //     }
    //   },
    //   async appDetails() {
    //     if(!this.image) {
    //       return ''
    //     }
    //     if(!this.image.appDetails) {
    //       return ''
    //     }
    //     if(!this.image.encrypted) {
    //       return this.image.appDetails
    //     }
    //
    //     try {
    //       let e2eeHelper = E2EEHelperCache.getE2EEHelper(this.accountId)
    //       let ret = await e2eeHelper.decryptString(this.deviceId, this.image.appDetails, this.image.takenAt)
    //       if(ret.length > 100) {
    //         ret = ret.substring(0, 100)
    //         ret += '...'
    //       }
    //       return ret
    //     } catch(e) {
    //       if(e.name) {
    //         let ret = `Error Decrypting: ${e.name}`
    //         if(e.message) {
    //           ret += `: ${e.message}`
    //         }
    //         return ret
    //       }
    //       return e
    //     }
    //   }
    // },
    computed: {
      timestampString() {
        let m = moment(this.image.takenAt)
        return m.format("MMM D LT")
      },
      height() {
        return this.width * this.image.imageRatio
      }
    },
    watch: {
      image: function(newValue, oldValue) {
        if(!newValue) {
          this.handleNewImage()
        }
        if(newValue !== oldValue) {
          if(oldValue) {
            if(newValue.fileKey !== oldValue.fileKey) {
              this.handleNewImage()
            }
          } else {
            this.handleNewImage()
          }
        }
      }
    }
  }
</script>

<style>
  .no-risk {
    border-radius: 4px;
    border: 1px solid black;
  }
  .low-risk {
    border-radius: 4px;
    border: 1px solid black;
  }
  .medium-risk {
    border-radius: 4px;
    border: 1px solid black;
  }
  .unknown-risk {
    border-radius: 4px;
    border: 1px solid black;
  }
  .high-risk {
    border-radius: 4px;
    border: 3px solid #b71c1c;
  }

  .high-risk.blur-high-risk {
    filter: blur(10px);
    border: 20px solid #b71c1c;
  }

  .thumbnail-img {
    border-radius: 4px;
  }

  .meta-data {
    padding-left: 2px;
    padding-bottom: 2px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 1);
    color: white;
    font-size: x-small;
    opacity: 0.75;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
    white-space: nowrap;
    line-height: 14px;
  }

  .meta-data.high-risk {
    background-color: rgba(183, 28, 28, 0.75);
  }

</style>