var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "thumbnail-img elevation-3 d-flex justify-center align-center",
      style:
        "position: relative; cursor: pointer; height: " +
        _vm.height +
        "px; width: " +
        _vm.width +
        "px;",
      on: { click: _vm.viewImage }
    },
    [
      _vm.decryptionError
        ? _c(
            "div",
            {
              staticClass: "text-center align-center mx-auto d-flex",
              style: "height: " + _vm.height + "px; width: " + _vm.width + "px;"
            },
            [
              _c("v-icon", { attrs: { color: "error" } }, [
                _vm._v("\n        warning\n      ")
              ]),
              _vm.decryptionError.status === "SIGNER_NOT_FOUND"
                ? [
                    _vm._v(
                      "\n        The encryption key used to sign this image was not found.\n      "
                    )
                  ]
                : _vm.decryptionError.message ===
                  "Recipient defined with id is not found within message info during data decryption."
                ? _c("div", [
                    _vm._v(
                      "\n        Please restart this monitored device.  The image was encrypted using an old encryption key.\n      "
                    )
                  ])
                : _vm.decryptionError.message ===
                  "Could not get public key from the second argument.Expected a Virgil Card or a Public Key object. Got boolean"
                ? [
                    _vm._v(
                      '\n        Please close and reopen the Truple app. If this issue persists, please email support@truple.io and tell us code "dupekey".\n      '
                    )
                  ]
                : _vm.decryptionError.message ===
                  "This identity is not registered"
                ? _c("div", [
                    _vm._v(
                      "\n        Please refresh the page or close and reopen the app to provide your E2EE Recovery Passcode.\n      "
                    )
                  ])
                : [
                    _c("span", { staticClass: "text-truncate" }, [
                      _vm._v(_vm._s(_vm.decryptionError.message))
                    ])
                  ]
            ],
            2
          )
        : _c("div", {
            class: [
              "image-holder",
              _vm.image.risk,
              _vm.$store.state.viewSettings &&
              _vm.$store.state.viewSettings.blurHighRiskScreenshots
                ? "blur-high-risk"
                : ""
            ],
            style:
              "height: " +
              _vm.height +
              "px; width: " +
              _vm.width +
              "px; background-image: url('" +
              _vm.imageSource +
              "'); background-size: cover; background-repeat: no-repeat;"
          }),
      _vm.showMetaData
        ? _c(
            "div",
            {
              class: "meta-data " + _vm.image.risk,
              staticStyle: {
                position: "absolute",
                bottom: "0",
                left: "0",
                "z-index": "2"
              }
            },
            [
              _c("div", [_vm._v(_vm._s(_vm.image.deviceName))]),
              _c(
                "div",
                [
                  _c("span", { staticStyle: { float: "left" } }, [
                    _vm._v(_vm._s(_vm.timestampString))
                  ]),
                  _c("span", { staticStyle: { float: "right" } }, [
                    _vm._v(_vm._s(_vm.image.encrypted ? _vm.keyEmoji : ""))
                  ]),
                  _vm.image.neverBeforeSeen
                    ? _c(
                        "v-icon",
                        {
                          staticStyle: { float: "right" },
                          attrs: { color: "white", small: "" }
                        },
                        [_vm._v("fiber_new")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }