<template>
  <div>
    <template v-if="state === states.decrypted || imageSource !== null">
      <pinch-zoom
        ref="pinchZoom"
        disableZoomControl="disable"
      >
        <v-img
          :eager="eager"
          :class="{ 'blur-high-risk' : $store.state.viewSettings && $store.state.viewSettings.blurHighRiskScreenshots && image.risk === 'high-risk' }"
          :contain="true"
          :src="imageSource"
          :style="`height: 98vh; width: 98vw; min-height: ${minHeight}px;`" />
      </pinch-zoom>
    </template>
    <div
      v-else-if="state === states.error"
      :style="`width: 100%; min-height: ${minHeight}px; height: 100%; background: white;`"
      class="text-center pa-3"
    >
      <v-icon color="error">
        warning
      </v-icon>

      <template v-if="decryptionError.status === 'SIGNER_NOT_FOUND'">
        The encryption key used to sign this image was not found.
      </template>
      <template v-else-if="decryptionError.message === 'Recipient defined with id is not found within message info during data decryption.'">
        The image was encrypted using an old encryption key.
      </template>
      <template v-else-if="decryptionError.message === 'Could not get public key from the second argument.Expected a Virgil Card or a Public Key object. Got boolean'">
        There was a decryption error.  Please refresh the page.  If the issue persists reach out to support@truple.io and indicate you have a duplicate e2ee card.
      </template>
      <template v-else>
        <div class="pa-2">{{decryptionError.message}}</div>
      </template>

      <div class="caption">{{`AccountId: ${accountId}`}}</div>
      <div class="caption">{{`DeviceId: ${deviceId}`}}</div>
    </div>
  </div>
</template>

<script>
  import PinchZoom from 'vue-pinch-zoom';
  import ImageCache from "../../services/ImageCache";
  import ImageUtils from "../../services/ImageUtils";

  let states = Object.freeze({
    encrypted: 'encrypted',
    decrypting: 'decrypting',
    decrypted: 'decrypted',
    error: 'error'
  })

  export default {
    name: "EncryptedImage",
    components: {
      PinchZoom
    },
    data() {
      return {
        imageSource: null,
        states: states,
        state: states.encrypted,
        decryptionError: null,
        imageUrlProperty: 'downloadUrl'
      }
    },
    props: {
      isVisible: {
        type: Boolean,
        default: false
      },
      eager: {
        type: Boolean,
        required: false,
        default: true
      },
      image: {
        type: Object,
        // required: true
      },
      minHeight: {
        type: Number,
        required: true
      },
      accountId: {
        type: String,
        // required: true
      },
      deviceId: {
        type: String,
        // required: true
      }
    },
    methods: {
      async decryptImage() {
        if(!this.deviceId || !this.accountId) {
          return
        }

        if(this.state !== states.encrypted) {
          return
        }

        if(!this.image) {
          return
        }

        if(!this.image.encrypted) {
          let imageData = await fetch(this.image[this.imageUrlProperty])
          if(imageData.statusCode === 403) {
            throw new Error('Your credentials have expired, please reload the page.')
          }
          let dataBlob = await imageData.blob()
          this.imageSource = await ImageUtils.blobToBase64(dataBlob)
          this.state = states.decrypted
          return
        }

        this.state = states.decrypting
        try {
          let base64 = await ImageCache.getImage(this.accountId, this.deviceId, this.image.takenAt, this.image[this.imageUrlProperty])
          this.imageSource = base64
          this.state = states.decrypted
        } catch(e) {
          this.state = states.error
          this.decryptionError = e
          console.log('error decrypting')
          console.log(e.message)
        }
      },
      reset() {
        this.imageSource = null
        this.state = states.encrypted
        this.decryptionError = null
        this.decryptImage()
      }
    },
    computed: {

    },
    watch: {
      isVisible: function (newValue) {
        console.log('isVisible watch')
        if(newValue) {
          this.decryptImage()
        }
      },
      image: function(newValue, oldValue) {
        console.log('image watch')
        if(newValue !== oldValue) {
          console.log('resetting!')
          this.reset()
        }
      }
    },
    beforeMount() {
      this.decryptImage()
    },
    beforeDestroy() {
      // console.log('beforeDestroy')
    }
  }
</script>

<style scoped>


.blur-high-risk {
  filter: blur(25px)
}

.blur-high-risk:active {
  filter: none;
}

</style>